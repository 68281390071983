"use client";

type Props = {
  iconClass: string;
};

export const JupiterIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="100%"
      height="100%"
      viewBox="0 0 256 256"
      fill="currentColor"
      className={props.iconClass}
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path d="M1046 2544 c-162 -29 -344 -105 -496 -207 -96 -64 -263 -231 -327 -327 -154 -230 -223 -456 -223 -731 0 -274 69 -499 223 -729 64 -96 231 -263 327 -327 230 -154 456 -223 731 -223 274 0 499 69 729 223 96 64 263 231 327 327 154 230 223 456 223 731 0 274 -69 499 -223 729 -64 96 -231 263 -327 327 -157 105 -334 178 -505 207 -112 20 -350 20 -459 0z m429 -299 c112 -20 243 -69 347 -131 107 -63 266 -220 332 -326 49 -79 116 -229 116 -262 0 -12 -38 21 -110 97 -273 285 -655 500 -1025 576 -53 11 -99 22 -102 25 -2 2 19 9 49 15 29 5 62 12 73 14 48 11 240 6 320 -8z m-412 -220 c177 -31 407 -123 587 -234 128 -80 235 -168 345 -283 163 -171 245 -330 245 -473 0 -41 -3 -75 -7 -75 -5 0 -29 42 -55 93 -200 391 -698 745 -1210 859 -83 18 -133 23 -260 23 -87 0 -158 3 -158 6 0 21 77 63 152 83 70 19 254 20 361 1z m-135 -324 c271 -59 565 -211 787 -408 234 -206 373 -471 336 -637 -12 -56 -45 -110 -63 -104 -6 2 -19 36 -28 74 -106 417 -646 845 -1165 924 -157 24 -393 7 -469 -32 -17 -9 -18 -7 -12 23 3 18 17 47 31 65 33 43 152 101 230 114 83 13 248 4 353 -19z m-117 -286 c466 -98 906 -470 976 -824 21 -107 16 -121 -57 -156 -33 -16 -61 -27 -63 -24 -1 2 -13 36 -25 74 -59 190 -223 393 -428 530 -270 182 -573 257 -839 210 -43 -8 -82 -12 -85 -9 -3 3 -5 35 -5 72 l0 66 58 29 c114 57 292 69 468 32z m-61 -404 c362 -92 659 -342 761 -638 10 -31 16 -60 12 -64 -5 -4 -48 -13 -98 -20 -82 -11 -90 -10 -100 6 -65 112 -203 259 -312 333 -167 113 -398 192 -563 192 l-47 0 -31 69 c-53 117 -53 117 10 130 29 6 62 13 73 15 41 10 223 -4 295 -23z m-94 -337 c178 -51 315 -135 448 -274 47 -50 86 -95 86 -101 0 -14 -39 -11 -130 12 -195 50 -354 143 -494 289 -47 50 -86 96 -86 101 0 14 74 2 176 -27z" />
      </g>
    </svg>
  );
};
