"use client";

type Props = {
  iconClass: string;
};

export const RaydiumIcon = (props: Props) => {
  return (
    <svg
      className={props.iconClass}
      width="439"
      height="500"
      viewBox="0 0 439 500"
      color="#000"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        color="#000"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.896 47.8638C90.9117 74.0967 41.9339 102.524 27.0533 111.033L0 126.505V251.647C0 363.824 0.415238 376.948 4.00892 378.327C6.21597 379.175 32.5445 394.138 62.5195 411.581C92.4971 429.023 139.414 456.321 166.784 472.246C198.654 490.788 218.253 500.751 221.291 499.956C225.609 498.826 255.209 482.035 391.958 403.145L437.886 376.651V276.324C437.886 221.146 437.297 176.001 436.577 176.001C435.858 176.001 430.761 178.847 425.253 182.325L415.237 188.647L415.191 276.068L415.146 363.486L389.396 378.402C375.233 386.606 352.888 399.549 339.739 407.162C308.72 425.125 281.913 440.715 248.968 459.947C217.128 478.535 220.443 478.313 195.269 463.521C184.081 456.945 140.946 432.156 99.4177 408.433L23.9076 365.298L23.2533 251.853L22.5989 138.408L30.803 134.198C35.3152 131.885 55.994 120.158 76.7559 108.141C198.108 37.9031 216.406 27.5222 218.878 27.5222C221.137 27.5222 298.09 71.0793 354.257 104.15L368.592 112.59L377.903 107.842C383.024 105.229 387.854 102.053 388.639 100.785C389.751 98.9857 221.439 -0.666054 218.422 0.00335748C218.017 0.0939546 180.88 21.6309 135.896 47.8638ZM402.032 119.02C392.852 124.267 392.6 124.796 392.595 138.914L392.587 153.417L403.61 158.772L414.633 164.128L425.63 158.075C436.167 152.277 436.658 151.482 437.378 139.128L438.13 126.235L426.365 119.662C412.733 112.042 414.18 112.079 402.032 119.02ZM133.379 159.643V176.001H190.083C247.71 176.001 258.841 177.526 267.508 186.606C286.493 206.494 282.477 237.01 259.249 249.354C251.437 253.507 244.079 254.015 191.93 254.015H133.379V309.38V364.745H149.737H166.095V325.929V287.116L214.539 286.293C258.821 285.543 263.877 284.994 273.387 279.908C317.367 256.386 326.547 200.303 292.077 165.748C270.893 144.513 265.492 143.285 193.329 143.285H133.379V159.643ZM259.837 307.548C243.706 310.648 243.691 309.153 260.109 337.603L273.591 360.97L293.071 361.71L312.552 362.45L304.379 347.868C286.649 316.235 278.85 304.362 276.014 304.676C274.383 304.86 267.105 306.151 259.837 307.548Z"
      />
    </svg>
  );
};
